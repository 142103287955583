import React, { useState } from "react";

export const Navbar: React.FC = () => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    return (
        <div>
            <nav className="font-tiki bg-light-blue px-8 justify-between items-center flex mx-auto z-50" id="navbar">
                <div className="gap-x-12 items-center flex ">
                    <a className="p-1.5 -m-1.5" href="/#">
                        <img alt="logo" className="w-20" src="octopus.png" />
                    </a>
                    <div className="hidden lg:flex gap-x-8 text-2xl tracking-wider">
                    </div>
                </div>
                <div className="lg:hidden flex text-white">
                    <button onClick={() => setMobileMenuOpen(true)} className="flex items-center p-3">
                        <svg className="block h-4 w-4 fill-current" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <title>Mobile menu</title>
                            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
                        </svg>
                    </button>
                </div>
                <div className="hidden lg:flex space-x-4">
                    <div className="flex items-center space-x-4">
                        <a href="https://www.dextools.io/app/fr/base/pair-explorer/0x7dE07815FA7ad3e3738D0035774DB40d895f413C" className="bg-white size-8 rounded-full flex items-center justify-center shadow" target="_blank" rel="noreferrer">
                            <img className="size-5" src="/dextools.png" alt="telegram"/>
                        </a>
                        <a href="https://dexscreener.com/base/0x7dE07815FA7ad3e3738D0035774DB40d895f413C" className="bg-white size-8 rounded-full flex items-center justify-center shadow" target="_blank" rel="noreferrer">
                            <img className="size-5" src="/dexscreener.png" alt="telegram"/>
                        </a>
                        <a href="https://t.me/octobasecoin" className="bg-white size-8 rounded-full flex items-center justify-center shadow" target="_blank" rel="noreferrer">
                            <img className="size-5" src="/telegram-34.png" alt="telegram"/>
                        </a>
                        <a href="https://twitter.com/OctoBasecoin" className="bg-white size-8 rounded-full flex items-center justify-center shadow" target="_blank" rel="noreferrer">
                            <img className="size-5" src="/x-34.png" alt="x"/>
                        </a>
                    </div>
                    <div className="flex items-center">
                        <a href="https://app.uniswap.org/explore/tokens/base/0x9f174Caf21A3CB6442361bc9d99D66fD4644D886" target="_blank" rel="noreferrer">
                            <button className="bg-gradient-to-t from-[#143A9E] to-[#5897F4] text-white font-tiki px-6 w-full lg:w-36 py-4 xl:py-2 rounded border-2 border-white shadow">Buy now</button>
                        </a>
                    </div>
                </div>
            </nav>
            <div className={"relative z-50 " + (mobileMenuOpen ? 'visible' : 'hidden')}>
                <nav className="fixed top-0 left-0 bottom-0 right-0 flex flex-col py-6 px-6 overflow-y-auto bg-white">
                    <button onClick={() => setMobileMenuOpen(false)} className="ml-auto mr-0">
                        <svg className="h-6 w-6 cursor-pointer" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                        </svg>
                    </button>
                    <div>
                        <div className="pt-6 flex flex-row justify-center text-3xl gap-8">
                        <a href="https://www.dextools.io/app/fr/base/pair-explorer/0x7dE07815FA7ad3e3738D0035774DB40d895f413C" className="bg-white size-8 rounded-full flex items-center justify-center shadow" target="_blank" rel="noreferrer">
                            <img className="size-5" src="/dextools.png" alt="telegram"/>
                        </a>
                        <a href="https://dexscreener.com/base/0x7dE07815FA7ad3e3738D0035774DB40d895f413C" className="bg-white size-8 rounded-full flex items-center justify-center shadow" target="_blank" rel="noreferrer">
                            <img className="size-5" src="/dexscreener.png" alt="telegram"/>
                        </a>
                        <a href="https://t.me/octobasecoin" className="bg-white size-8 rounded-full flex items-center justify-center shadow" target="_blank" rel="noreferrer">
                            <img className="size-5" src="/telegram-34.png" alt="telegram"/>
                        </a>
                        <a href="https://twitter.com/OctoBasecoin" className="bg-white size-8 rounded-full flex items-center justify-center shadow" target="_blank" rel="noreferrer">
                            <img className="size-5" src="/x-34.png" alt="x"/>
                        </a>
                        </div>
                        <p className="my-4 text-xs text-center">
                            <span>© 2024 OCTO. All rights reserved</span>
                        </p>
                    </div>
                </nav>
            </div>
        </div>
    );
}