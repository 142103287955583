import React from "react";
import { Navbar } from "./Navbar";
import { Main } from "./Main";

export const Home: React.FC = () => {
  return (
    <div className="main">
      <Navbar/>
      <Main/>
    </div>
  );
};