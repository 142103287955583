import React from "react";

export const Main: React.FC = () => {
    return (
        <div className='flex justify-center lg:block'>
            <div className="px-8 gap-x-10 items-center flex">
                <div className="hidden lg:flex w-1/2">
                    <img className="pl-12" src="/octopus.png" alt="cat"/>
                </div>
                <div className="flex flex-col items-center gap-4">
                    <h1 className="text-center font-tiki text-6xl pt-6">$OCTOBASE</h1>
                    <div className="bg-white px-2 border-2 border-dark rounded-full text-gray text-sm py-1 w-fit">
                        <a className="text-center" href="https://basescan.org/address/0x9f174Caf21A3CB6442361bc9d99D66fD4644D886" target="_blank" rel="noreferrer">0x9f174Caf21A3CB6442361bc9d99D66fD4644D886</a>
                    </div>
                    <div className="w-full pb-12">
                        <a className="text-center" href="https://app.uniswap.org/explore/tokens/base/0x9f174Caf21A3CB6442361bc9d99D66fD4644D886" target="_blank" rel="noreferrer">
                            <button className="bg-gradient-to-t from-[#143A9E] to-[#5897F4] text-white font-tiki px-6 py-4 xl:py-2 w-full rounded border-2 border-white shadow">Buy now</button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}